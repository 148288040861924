.text-phone-title-list ul {
  list-style-image: url('../../../assets/icons/check4-Photoroom.png');
  font-size: 16px;
}
.ipphone-service-title {
  background-color: #f4f4f4;
  color: #232323;
  padding: 50px;
}

.ipphone-service-title h1 {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  font-size: 2em;
  --fontSize: 35;
  line-height: 1.14;
}
.ipphone-service-title h5 {
  text-align: center;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 1.3em;
  --fontSize: 35;
  line-height: 1.14;
}

.ipphone-service-title p {
  text-align: center;
  font-style: normal;
  font-weight: 300;
  margin: 0;
  font-size: 1.1em;
  --fontSize: 19;
  line-height: 1.5;
  letter-spacing: 1px;
}

.ipphone-service {
  background-color: #373737;
  color: #FFF;
  margin-bottom: 0;
  padding: 50px;
}

.ipphone-service2 {
  margin-top: 0;
  padding: 50px;
  background-color: #232323;
  color: #FFF;
}

.text-phone-title-list h1 {
  text-align: left;
  font-style: normal;
  font-weight: 700;
  margin: 0 0 0 40px;
  font-size: 2em;
  --fontSize: 35;
  line-height: 1.14;
}

.text-phone-title-list h3 {
  color: rgb(10, 226, 10);
  font-size: 1.2em;
  margin-left: 35px;
  font-weight: 200;
}

.text-phone-title-list h4 {
  color: rgb(10, 226, 10);
  font-size: 1.2em;
  margin-left: 35px;
  font-weight: 200;
}

.text-phone-title-list h5 {
  color: rgb(10, 226, 10);
  font-size: 1.2em;
  margin-left: 35px;
  font-weight: 200;
}

.text-phone-title-list h6 {
  color: rgb(49, 239, 93);
  font-size: 1.2em;
  margin-left: 35px;
  font-weight: 200;
}

.text-phone-title-list p {
  margin-left: 35px;
}

.text-phone-title-list span {
  margin-left: 35px;
}

.text-phone-title-list li {
  text-align: left;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  font-size: 1.1em;
  --fontSize: 35;
  line-height: 2;
  letter-spacing: 1px;
}

.fa-check-square-o {
  color: green;
  font-size: 2.5em;
}

.fa-check {
  color: green;
  font-size: 2.5em;
}
.list {
  margin-left: 40px !important;
}

.list ul {
  list-style: none;
}
.list li {
  margin: 0 !important;
  text-indent: 0 !important;
}
.card-text p{
  font-size: 12px;
}
.card-obs p{
  color: #0AE20A;
  font-size: 18px;
}
.ipphone-infra {
  width: 500px;
}

.ipphone-infra3 {
  width: 100%;
  margin-top: -50px;
}

.ipphone-service img,
.ipphone-service2 img {
  width: 100%;
  max-width: 70%;
  /* Garante que as imagens não ultrapassem a largura do contêiner */
  height: auto;
  /* Mantém a proporção da imagem */
  display: block;
  margin: 0 auto;
  /* Centraliza a imagem horizontalmente */
}

@media (max-width:768px) {

  .ipphone-service img,
  .ipphone-service2 img {
    width: 80%;
    max-width: 80%;
    margin: 20px !important;
    /* Garante que as imagens não ultrapassem a largura do contêiner */
    height: auto;
    /* Mantém a proporção da imagem */
    display: block;
    margin: 0 auto;
    /* Centraliza a imagem horizontalmente */
  }

  .ipphone-service-title {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .ipphone-service-title h1 {
    margin-top: 20px;
    font-size: 20px;
  }

  .ipphone-service-title p {
    font-size: 18px;
    text-align: justify;
  }

  .text-phone-title-list {
    width: 100% !important;
    margin: 0;
    padding: 0;
  }

  .text-phone-title-list h1 {
    font-size: 20px;
  }
}