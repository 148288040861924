.curriculo-view {
  padding: 20px;
}

.curriculo-view h2 {
  margin-bottom: 10px;
}

.curriculo-view img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 15px;
}

.curriculo-view .card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.curriculo-view .card-header {
  background-color: #F4F4F4;
  color: #555;
  font-size: 1.25rem;
}

.curriculo-view .card-body {
  padding: 20px;
}
