.body-post {
  background-color: #f4f4f4;
  padding: 20px 0;
}
.postagem {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.foto-postagem {
  padding: 8px;
  background-color: #FFF;
  border: 1px solid #CCC;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin: 5px;
}

.foto-postagem img {
  width: 100%;
}

.postagem-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.postagem-body img {
  width: 50px;
}

.postagem-body h2 {
  color: #B200B2;
  text-shadow: 1px 1px 2px black;
}

.postagem-body h3 {
  color: #383738;
  font-size: 18px;
}

 /*  */
 * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.buttons {
  margin: 20px 0 40px 0;
  text-align: center;
}

.buttons a {
  text-decoration: none;
}

.btn-hover {
  width: 200px;
  padding: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
 
  text-align: center;
  border: none;
  background-size: 300% 100%;

  border-radius: 20px;
  transition-duration: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  transition-duration: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-7 {
  background-image: linear-gradient(to right, #6253e1, #852D91, #A3A1FF, #c41fc4);
  box-shadow: 0 4px 15px 0 rgba(126, 52, 161, 0.75);
}

@media (max-width: 768px) {
  .postagem-body {
    margin-top: 20px !important;
  }
  .foto-postagem img {
      width: 80%; /* Reduz o tamanho da imagem para não ocupar toda a largura */
      margin: 0 auto; /* Centraliza a imagem */
  }

  .postagem-body img {
      width: 30px; /* Reduz o tamanho do ícone do wifi */
  }

  .postagem-body h2, .postagem-body h3 {
      font-size: 16px; /* Reduz o tamanho da fonte para melhorar a legibilidade */
  }

  .postagem {
      flex-direction: column; /* Muda o layout para coluna */
  }

  .body-post {
      padding: 10px; /* Reduz o preenchimento */
  }

  .btn-hover {
      width: 100%; /* Faz o botão ocupar a largura inteira */
      padding: 15px; /* Ajusta o preenchimento */
  }
  .buttons {
    margin: 20px 0 !important;
  }
}

@media (max-width: 480px) {
  .foto-postagem img {
      width: 100%; /* Aumenta a largura da imagem para preencher o espaço disponível */
  }

  .postagem-body img {
      width: 20px; /* Reduz ainda mais o tamanho do ícone */
  }

  .postagem-body h2, .postagem-body h3 {
      font-size: 14px; /* Reduz ainda mais o tamanho da fonte */
  }

  .buttons {
      margin: 10px 0; /* Reduz a margem ao redor dos botões */
  }
}
