.produtos-title {
  margin-top: 20px;
  text-align: center;
  color: #333;
}

.produto-card {
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.produto-card:hover {
  transform: translateY(-5px);
}

.produto-preco {
  font-size: 1.2em;
  font-weight: bold;
  color: #FF5733;
}

.custom-button {
  background-color: #007bff;
  border: none;
}
