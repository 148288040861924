.separator-phone {
  width: 100%;
}
.separator-phone img{
  width: 100%;
}
.phone-title {
width: 100%;
display: flex;
flex-direction: column;
align-items: start;
justify-content: start;
}
.phone-title img{
  width: 50px;
  height: 50px;
}
.phone-title h2 {
  color: #B200B2;
  text-shadow: 1px 1px 2px black;
}

@media (max-width: 768px) {
  .phone-title img{
    width: 20px;
    height: 20px;
  }
  .phone-title h2 {
    color: #B200B2;
    text-shadow: unset;
    font-size: 18px;
  }
}