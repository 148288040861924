.cidades-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
}

.cidades-title {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.cidades-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.cidade-card {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  /* width: 300px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.cidade-card:hover {
  transform: translateY(-5px);
}

.cidade-card h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.cidade-card p {
  font-size: 1em;
  margin: 5px 0;
}

.cidade-card a {
  color: #007bff;
  text-decoration: none;
}

.cidade-card a:hover {
  text-decoration: underline;
}
.cidade-info {
  text-align: left;
}