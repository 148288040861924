.about-section {
  margin-top: 20px;
}

.video-wrapper {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: 8px;
}

.about-section h2, 
.about-section h3 {
  margin-top: 20px;
  color: #2c3e50;
}

.about-section p {
  text-align: justify;
  line-height: 1.6;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .about-section {
    padding: 10px;
  }
}
