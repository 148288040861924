.jobs {
  width: 100%;
}

.jobs img {
  border-top: 1px solid #ccc;
  width: 100%;
  margin-bottom: 0;
}

.trash {
  color: red;
}
.trash span {
  margin-left: 5px;
}

.separator-jobs {
  margin-top: -10px;
  margin-bottom: 30px;
}

.title-jobs {
  margin-top: 30px;
  color: #645f5f;
}

.title-jobs img {
  width: 120px;
}

.editar-curriculo {
  min-height: 250px;
}

.editar-curriculo h3 {
  font-size: 20px;
}

.editar-curriculo p {
  font-size: 16px;
  text-align: justify;
}

.editar-curriculo-list {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
}

.editar-curriculo-list-int img {
  width: 40px;
}

.curriculo-foto img {
  width: 100%;
}

.cadastrar-curriculo {
  display: flex;
  flex-direction: column;
}

.botao-curriculo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.botao-td {
  width: 7%;
}

.center-icon-button {
  margin-left: -5px;
}