html, body {
  overflow-x: hidden; /* Esconde rolagem horizontal */
  overflow-y: auto; /* Permite rolagem vertical */     
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F9FA !important;
}
a:hover {
  background-color: inherit;
}

.container {
  max-width: 100%; /* Garante que contêineres principais não excedam 100% da largura */
}