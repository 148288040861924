.trabalhe-conosco-container {
  position: fixed;
  bottom: 100px;
  left: 0;
  z-index: 1000;
}

.trabalhe-conosco-btn {
  /* background-color: #fff;
  border: 2px solid #28a745;
  border-radius: 50%; */
  padding-right: 8px;
  margin-left: -120px;
  width: 160px;
  height: 50px;  
  display: flex;
  justify-content: end;
  align-items: center;
  color: #28a745;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.trabalhe-conosco-btn:hover {
  background-color: #28a745;
  color: #fff;  
  margin-left: 0;
}
.trabalhe-conosco-btn span {
  font-size: 12px;
  text-transform: uppercase;
}

.trabalhe-conosco-popup {
  position: absolute;
  bottom: 60px;
  left: 0;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 300px;
  height: 450px;
  text-align: center;
  z-index: 1000;
}

.trabalhe-conosco-popup img {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.trabalhe-conosco-btn-popup {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px !important;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.trabalhe-conosco-btn-popup:hover {
  background-color: #218838;
}

/* Animação do ícone */
@keyframes colorChange {
  0% {
    color: #ccc;
  }
  50% {
    color: #000;
  }
  100% {
    color: #fff;
  }
}

.animated-icon i {
  animation: colorChange 8s infinite; /* Troca de cor a cada 2 segundos */
}
