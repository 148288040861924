.erro {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
}
.erro img {
  max-width: 40%; /* Define a largura máxima da imagem como 100% do contêiner pai */
  height: auto; /* Mantém a proporção da imagem, ajustando automaticamente a altura */
}