.slider-container {
  margin: 10px auto;
  padding: 0px;
  width: 100%;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-image {
  width: 100px; /* Adjust as needed */
  height: auto;
  width: calc(100% - 4px); /* Ajuste para reduzir a largura e diminuir espaços */
  box-sizing: border-box; /* Inclui padding e border dentro da largura */
}
.animal img {
  width: 100%;
}

.slick-slide > div {
  padding: 0 !important; /* Força a aplicação do padding */
}

