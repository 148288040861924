.text-center h1 {
  color: #555 !important;
}
.text-center h2 {
  color: #555 !important;
}
.app-card {
  margin-bottom: 20px;
}

.app-image {
  max-height: 150px;
  object-fit: contain;
  margin-bottom: 15px;
}

.app-card .card-title {
  font-size: 1.2em;
  font-weight: bold;
}
