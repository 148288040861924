.rural {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rural img{
    width: 100%;
    margin: 20px 0;
}
.container-fluid {
    position: relative;
  }
  
  .ver-planos {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .sub-rural {
    color: #FFF !important;
    text-shadow: 1px 1px 2px black;
  }