/* Sem prefixo */
input::placeholder {
  font-size: 11px;
  color: #888;
}

/* WebKit-based browsers (Chrome, Safari) */
input::-webkit-input-placeholder {
  font-size: 11px;
  color: #888;
}

/* Mozilla Firefox */
input::-moz-placeholder {
  font-size: 11px;
  color: #888;
}

/* Microsoft Edge */
input:-ms-input-placeholder {
  font-size: 11px;
  color: #888;
}

/* Internet Explorer 10-11 */
input::-ms-input-placeholder {
  font-size: 11px;
  color: #888;
}
input[type="file"] {
  appearance: none; /* Remove o estilo padrão */
  -webkit-appearance: none; /* Para navegadores baseados em WebKit */
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  font-size: 11px;
  border-radius: 4px;
  cursor: pointer;
}

.input-group-text {
  width: 40px;
  height: 40px;
  border-radius: 5px 0px 0px 5px !important;
  background-color: #E9ECEF;
}

.text-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5px;
  width: 100%;
}

.text-icon img {
  width: 13px;
}
.text-icon input {
  border-radius: 0px 5px 5px 0px !important;
}
.text-popover {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* .text-icon textarea {
  border-radius: 0px 5px 5px 0px !important;
} */
.select-input {
  display: flex;
  width: 100%;
}
.select-rede {
  width: 30%;
}
.select-rede select {
  height: 38px;
  border: 1px solid #ccc !important;
  border-radius: 5px 0 0 5px;
}
.url-rede {
  width: 70%;

}
label {
  font-size: 12px;
  position: relative;
  bottom: -3px;
}
p {
  font-size: 12px;
}
.orientacao {
  font-size: 14px;
}
.icones-curriculo {
  width: 36px;
  height: 36px;
  border: 1px solid blue;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  cursor: pointer;
  color: blue;
}
.icones-curriculo :hover {
  background-color: blue;
  color: white;
}
/* Adicione estas classes para personalizar o estilo do DatePicker */
.custom-datepicker {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-datepicker:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.formato-invalido {
  border: 1px solid red;
}
.genero {
  display: flex;
  flex-direction: row;
  margin-bottom: -10px;
  font-size: 11px;
}
.genero-btn {
  margin-top: -7px;
}

