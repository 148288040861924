.SnowEffect {
  position: fixed; /* Fixa o efeito de neve sobre a página */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999; /* Coloca o efeito de neve sobre todo o conteúdo da página */
  pointer-events: none; /* Evita que o efeito de neve interfira nos cliques nos elementos da página */
}

.Snowflake {
  position: absolute;
  top: -10vh; /* Começa fora da tela */
  color: #FFFAFA;
  opacity: 0;
  animation: fall 16s linear infinite;
}

@keyframes fall {
  0% {
    opacity: 0;
    transform: translateX(0);
  }
  10% {
    opacity: 0.9;
  }
  90% {
    opacity: 0.9;
    transform: translate(30vw, 97vh); /* Movimenta o floco de neve para baixo e um pouco para o lado */
  }
  100% {
    opacity: 0;
    transform: translate(40vw, 100vh); /* Sai da tela pela parte inferior */
  }
}
