.movel-container {
  width: 100%;
  max-width: 1980px;
  margin: 0 auto;
  overflow: hidden;
}

.top-image img {
  width: 100%;
  height: auto;
}

.cards-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
}

.slider-wrapper {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.card {
  flex: 0 0 33.33%;
  box-sizing: border-box;
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
  transition: opacity 0.3s ease-in-out;
}

/* Remover margin-right do último card */
.card:last-child {
  margin-right: 0;
}

.card-header h3 {
  margin: 0;
  font-size: 1.5em;
}

.card-header h5 {
  margin: 0;
  font-size: 1em;
  color: #666;
}

.card-body p {
  margin: 10px 0;
  font-size: 1em;
}

.card-footer p {
  margin: 0;
  font-size: 0.9em;
  color: #888;
}

.prev, .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #BA22A6;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 1.5em;
  z-index: 1;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.prev:hover, .next:hover {
  background-color: #f803d7;
}

/* Ajustes para dispositivos móveis */
@media (max-width: 767px) {
  .slider-wrapper {
    flex-direction: column; /* Exibe os cards em uma coluna */
    transform: translateX(0); /* Remove a transição do slider */
  }

  .card {
    flex: 1 0 100%; /* Cada card ocupa 100% da largura */
    margin-bottom: 20px; /* Espaçamento entre os cards */
    margin-right: 0; /* Remove o margin-right */
    opacity: 1; /* Garante opacidade total para todos os cards */
  }

  .prev, .next {
    display: none; /* Esconde os botões de navegação */
  }
}
