.card-home {  
  transition: opacity 1.0s ease-in;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  text-align: center;
}

.card-img {
  padding: 5px;
}

.card-title h3 {
  font-size: 18px;  
  padding-top: 10px;
}

.card-home img {
  width: 80%;
  margin: 0 auto;
}

.my-row h3 {
  text-align: center;
  padding: 20px;
}

.my-row-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
}

.my-row-content p {
  width: 70%;
  margin-bottom: 50px;
}

.my-row-content-top {
  max-height: 500px;
  padding: 40px;
  background-color: #242323;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  border-top: 1px solid #CCC;
}

.my-row-content-top h1 {
  margin-bottom: 20px;
}

.my-row-content-top img {
  width: 55px;
  margin-top: -15px;
}

.custom-button {
  background-color: #B148C6 !important;
  border: #B148C6 !important;
  color: #fff;
}

.custom-button:hover {
  background-color: #9C27B0 !important;
}

.custom-button-card {
  width: 100%;
  background-color: #9C27B0 !important;
  border: #B148C6 !important;
  color: #fff;
}

.custom-button-card:hover {
  background-color: #7a00cc !important;
}

.my-row {
  background-color: #F2F2F2;
  margin-bottom: 20px;
  height: auto;
  padding: 40px;
}

.my-row h1 {
  text-align: center;
  margin-bottom: 0;
}

.my-row h3 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: #690C68;
  margin-bottom: 0;
}

.my-row p {
  width: 100%;
  text-align: center;
  margin: 0 50px;
}

.my-row img {
  width: 100%;
}

.my-row-content-top-img {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.my-row-content-top-img img {
  width: 252px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .my-row-content-top {
    padding: 20px !important;
    text-align: center;
  }

  .my-row-content-top h1 {
    font-size: 24px;
  }

  .my-row-content-top h3 {
    font-size: 18px;
  }

  .my-row-content-top p {
    font-size: 14px;
    width: 90%;
  }

  .my-row-content-top-img img {
    width: 180px; /* Reduz a largura das imagens */
    margin: 10px;
  }

  .my-row p {
    margin: 0 20px;
  }
}

@media (max-width: 480px) {
  .my-row-content-top {
    padding: 10px;
  }

  .my-row-content-top h1 {
    font-size: 20px;
  }

  .my-row-content-top h3 {
    font-size: 16px;
  }

  .my-row-content-top p {
    font-size: 12px;
    width: 100%;
  }

  .my-row-content-top-img img {
    width: 140px; /* Reduz ainda mais a largura das imagens para dispositivos pequenos */
    margin: 5px;
  }

  .custom-button, .custom-button-card {
    width: 100%;
    padding: 10px;
  }
}
  