
.container-bg-old {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(0deg, transparent 0%, transparent 26%,rgba(142, 36, 180,0.15) 26%, rgba(142, 36, 180,0.15) 56%,transparent 56%, transparent 100%),linear-gradient(135deg, transparent 0%, transparent 36%,rgba(142, 36, 180,0.15) 36%, rgba(142, 36, 180,0.15) 71%,transparent 71%, transparent 100%),linear-gradient(135deg, transparent 0%, transparent 31%,rgba(142, 36, 180,0.15) 31%, rgba(142, 36, 180,0.15) 56%,transparent 56%, transparent 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255));
  /* background-image: linear-gradient(45deg, rgba(203, 78, 191,0.5) 0%, rgba(203, 78, 191,0.5) 12%,transparent 12%, transparent 20%,rgba(225, 118, 209,0.5) 20%, rgba(225, 118, 209,0.5) 24%,rgba(236, 138, 217,0.5) 24%, rgba(236, 138, 217,0.5) 35%,rgba(192, 58, 183,0.5) 35%, rgba(192, 58, 183,0.5) 36%,rgba(214, 98, 200,0.5) 36%, rgba(214, 98, 200,0.5) 100%),linear-gradient(135deg, rgb(203, 78, 191) 0%, rgb(203, 78, 191) 11%,transparent 11%, transparent 23%,transparent 23%, transparent 33%,rgb(236, 138, 217) 33%, rgb(236, 138, 217) 64%,rgb(192, 58, 183) 64%, rgb(192, 58, 183) 83%,rgb(214, 98, 200) 83%, rgb(214, 98, 200) 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255)); */
  /* Preenche a tela inteira */
 
  /* Garante que o background não se repita */
  background-repeat: no-repeat;
  /* Ajusta o tamanho do background */
  background-size: cover;
  /* Garante o alinhamento */
  background-position: center;
  /* Remove margens e padding do body */
  margin: 0;
  padding-top: 10px;

  border-top: 1px solid #e6e5e5;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.register-container-title {
  margin: 0;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e6e5e5;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: unset;
}

.register-title {
  width: 100%;

  color: #000;
  text-align: center;
  padding: 8px;
  border-radius: 5px;
}
.register-title img {
  width: 60px;
}

.register-title h2 {
  font-size: 20px;
  color: #000;
  z-index: 1;
}

.register-content {
  width: 30%;
}
.signIn {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 5px;
}
.signIn p {  
  font-size: 14px;
}

.btn-login {
  margin-top: 15px;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-login:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.btn-login:hover {
  background-color: #0056b3;
}
.avatar {
  font-size: 18px;
  padding: 20px;  
}
.avatar img {
  width: 90%;
  border-radius: 10px;
}
.form-group label,
.form-label {
  display: block; /* Garante que o label ocupe a linha inteira */
  text-align: left; /* Alinha o texto à esquerda */
  margin-bottom: 5px; /* Espaço entre o label e o input */
  font-weight: normal; /* Ajuste o peso da fonte, se necessário */
  font-weight: bold;
}