.phone {
  background-color: #f4f4f4;
  padding-bottom: 10px;
  margin-bottom: 40px;
}

.phone-container {
  width: 100%;
  padding: 40px;
}

.title-phone {
  display: flex;
  flex-direction: row;
}

.title-phone img {
  width: 60px;
  height: 70px;
}

.title-phone h1 {
  font-size: 28px;
}

.title-phone h3 {
  font-size: 18px;
}

.text {
  font-size: 16px;
  text-align: start;
}

/*  */
.container-phone {
  display: flex;
  align-items: last baseline;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.buttons-phone {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 320px;
  /* Altura do contêiner */
  margin-left: 40px;
  width: 100%;
}

.btn-hover {
  width: 200px;
  padding: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;

  text-align: center;
  border: none;
  background-size: 300% 100%;

  border-radius: 20px;
  transition-duration: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  transition-duration: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-9 {
  background-image: linear-gradient(to right, #f605e6, #9e06f1, #9e06f1, #f605e6);
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}

@media (max-width: 768px) {
  .phone-container {
    padding: 20px;
    height: auto;
  }

  .title-phone img {
    width: 30px;
    height: 35px;
  }

  .title-phone h1 {
    font-size: 22px;
  }

  .title-phone h3,
  .text {
    font-size: 14px;
  }

  .buttons-phone {
    margin-right: 20px;
    align-items: center;
    height: auto;
  }

  .btn-hover {
    width: 100%;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .phone-container {
    padding: 10px;
  }

  .title-phone h1 {
    font-size: 18px;
  }

  .title-phone h3,
  .text {
    font-size: 12px;
  }

  .buttons-phone {
    margin-right: 10px;
  }
}