.app-giga-play {
  text-align: center;
}

.text-center-title {
  font-size: 2em;
  font-weight: bold;
}

.combo-card {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.combo-image {
  max-width: 50%;
  height: auto;
}

.combo-card .card-body {
  padding: 20px;
}

.combo-card .card-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.combo-card .card-text {
  font-size: 1.2em;
}

.mais-button, .canais-button {
  margin-top: 10px;
  font-size: 1em;
  padding: 10px 20px;
}

.mais-button {
  background-color: #8303A6 !important;
  color: #fff;
  border: #8303A6 !important;
}

/* .canais-button {
  background-color: #28a745 !important;
  color: #fff;
  border: none;
} */

.mais-button:hover, .canais-button:hover {
  opacity: 0.8;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.plan-details {
  padding: 15px;
}

.plan-details h4 {
  font-size: 20px;
}

.plan-internet {
  font-size: 1.5rem;
  color: #840BAB;
  font-weight: bold;
}

.plan-canais {
  font-size: 1.5rem;
  color: #840BAB;
  font-weight: bold;
}
.plan-tv {
  margin-top: 40px;
  font-weight: bold;
  color: #840BAB;
}
.plan-tvprice {
  font-weight: bold;
}
.plan-price-discounted {
  display: flex;
  flex-direction: row;
}
.plan-internetprice {
  text-decoration: line-through;
  margin-right: 5px;
  color: red;
  font-style: italic;
}
.plan-discountedprice {
  margin-left: 5px;
  color: green;
  font-weight: bold;
}
.plan-price {
  margin-top: 30px;
  font-weight: bold;
}
.icon-tv img{
  width: 50px;
}
.plan-span {
  font-size: 12px;
  text-align: left;
}
.plan-span span {  
  margin-right: 30px;
  display: block;
}
.plan-total {
  font-weight: bold;
}


