.order-card {
  color: #fff;
}

.bg-c-blue {
  background: linear-gradient(45deg,#4099ff,#73b4ff);
}

.bg-c-green {
  background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}

.bg-c-yellow {
  background: linear-gradient(45deg,#FFB64D,#ffcb80);
}

.bg-c-red {
  background: linear-gradient(45deg,#c84848,#e37c7c);
}

.bg-c-pink {
  background: linear-gradient(45deg,#f504bd,#ff869a);
}

.bg-c-purple {
  background: linear-gradient(45deg,#a502fd,#b163f1);
}


.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
  box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.card h3 {
  margin-top: 10px;
  font-size: 20px;
  color: #f71339;
}

.card .card-block {
  padding: 25px;
}

.order-card i {
  font-size: 26px;
}
.card-block p {
  color: #000 !important;
}
.f-left {
  float: left;
}

.f-right {
  float: right;
}
.consulta {
  font-size: 12px;
}
.telephony-service {
  border: none;
}
