.my-row-footer {  
  min-height: 450px;
  padding: 5px;
  background-image: url('https://apisite.gigabytetelecom.com.br:4001/assets/images/bg2.png');
  /* background-image: url('http://localhost:4001/assets/images/bg2.png'); */
  border-top: 1px solid #CCC;
  color: #FFF;
}

.my-col-footer {
  width: 100%;
  height: auto;
  background-position: center center;
  background-image: url('https://apisite.gigabytetelecom.com.br:4001/assets/images/app2-Photoroom.png'); 
  /* background-image: url('http://localhost:4001/assets/images/app2-Photoroom.png');  */
  background-size: contain; /* A imagem ocupará todo o espaço da div */
  background-position: right center; /* Posiciona a imagem à direita e centraliza verticalmente */
  background-repeat: no-repeat; /* Evita que a imagem se repita */
  background-size: contain;
}
.my-col-footer h1 {
  margin: 20px 0 0 30px;
  font-size: 28px;
}
.my-col-footer p {
  width: 350px;
  margin: 0 0 0 30px;
}
.footer-contact p {
  margin: 0;
}
.footer {
  width: 100% !important;
  padding: 0 30px;
  height: 80px;
  background-color: #690C68;
  color: #ccc;
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  align-items: center;
}
.footer-links h3{
  margin-top: 20px;
}
.footer-links ul{
  margin-top: 10px;
}
.footer-links a {
  text-decoration: none;
  color: #FFF;
}
.footer-contact-social .fa{
  padding: 5px;
  color: #fff;
}
.store {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-top: 40px;
  margin-left: 30px;
}
.store img {
  width: 100px;
}
.contact-social h3 {
  margin-top: 20px;
}
  .scroll-to-top {
    position: fixed;
    background-color: #f803d7 !important;
    bottom: 45px;
    right: 40px;
    width: 50px;
    height: 50px;
    background-color: #BA22A6;
    color: white;
    border: none;
    border-radius: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    z-index: 1000;
  }

  .scroll-to-top:hover {
    background-color: #d304b8 !important;
    transform: translateY(-2px);
  }
  
  .scroll-to-top i {
    font-size: 22px;
    margin-left: -2px;
    color: #fff;
  }
  .scroll-to-top i:hover {
    color: #FFF;
  }
.private span {
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}
.qr img {
  width: 150px;
  margin: 10px 0 0 55px;
}

@media (max-width: 768px) {
    .my-row-footer {
        min-height: auto;
        padding: 20px 10px;
    }

    .my-col-footer {
        background-size: cover; /* Ajuste para cobrir completamente o fundo */
        padding: 10px; /* Reduz o preenchimento para mais espaço */
        background-image: none;
    }

    .my-col-footer h1, .my-col-footer p {
        margin-left: 10px; /* Reduz a margem lateral */
    }

    .my-col-footer p {
        width: auto; /* Permite que o parágrafo ajuste sua largura ao contêiner */
        font-size: 16px; /* Reduz o tamanho da fonte para melhor legibilidade */
    }

    .footer-contact-social .fa {
        font-size: 24px; /* Aumenta o tamanho dos ícones para melhor tocabilidade */
    }
    .contact-trabalhe a {
      text-decoration: none;
      font-size: 24px;
      color: #f803d7;
    }

    .footer-links ul {
        padding: 0;
        list-style-type: none; /* Remove estilos de lista para limpar o layout */
    }

    .footer-links {
        padding: 0 10px; /* Ajusta o preenchimento */
    }

    .footer {
        flex-direction: column; /* Altera a direção do flex para coluna */
        text-align: center; /* Centraliza o texto */
        padding: 10px;
    }

    .footer .logo {
        margin: 10px 0; /* Adiciona margem entre os elementos */
    }
  .logo-mob img {
      width: 150px;
    }
}

@media (max-width: 480px) {
    .footer-contact h3, .footer-contact p {
        font-size: 14px; /* Reduz ainda mais o tamanho da fonte */
    }

    .footer-contact-social .fa {
        font-size: 28px; /* Torna os ícones ainda maiores para facilitar a interação */
    }

    .my-col-footer h1 {
        font-size: 20px; /* Reduz o tamanho do título para melhor ajuste */
    }
}
