/* HalloweenGhosts.css */

.ghost-container {
  position: fixed;
  top: 30%;
  z-index: 9999;
  pointer-events: none;
  opacity: 0.7; /* Fantasmas semitransparentes */
}

.ghost-container.left {
  left: 10%;
}

.ghost-container.center {
  left: 50%;
  transform: translateX(-50%);
}

.ghost-container.right {
  right: 10%;
}

.ghost {
  width: 200px;
  animation: float 8s ease-in-out infinite;
}

/* Animação de flutuação para o fantasma */
@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
