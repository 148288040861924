/* HalloweenEyes.css */

.eyes-container {
  position: fixed;
  z-index: 9998;
  pointer-events: none;
  opacity: 0.8; /* Semitransparente para ser mais misterioso */
}

.eyes-container.top-left {
  top: 15%;
  left: 20%;
}

.eyes-container.top-right {
  top: 15%;
  right: 20%;
}

.eyes-container.bottom-left {
  bottom: 40%;
  left: 5%;
}

.eyes-container.bottom-right {
  bottom: 40%;
  right: 5%;
}

.eyes {
  width: 400px;
  animation: blink 6s ease-in-out infinite;
}

/* Animação de "piscar" */
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
}
