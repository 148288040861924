.canais-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
.canais-list-title {
  width: 100%;
}
.canal-item {
  flex: 1 1 100px; /* Ajuste essa largura conforme necessário */
  max-width: 100px; /* Ajuste essa largura conforme necessário */
}

.canal-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.rodape-cortesia {
  padding-top: 20px;
  font-size: 10px;
}