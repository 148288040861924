.nav-top {
    width: 100%;
    height: 50px;
}

.logo img {
    width: 180px;
}

.topo {
    border-bottom: 1px solid #c589d1;
}

.topo .nav-item {
    text-decoration: none;
    margin-right: 10px;
}

.social-icons {
    display: flex;
    align-items: center;
}

.down a:hover {
    color: #ad26c9;
}
.social-media {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Alinha os itens no início da linha */
    gap: 10px; /* Espaçamento entre os elementos */
    flex-wrap: wrap; /* Permite que os elementos quebrem para a próxima linha se necessário */
  }
  
  .social-media a {
    text-decoration: none;
    color: inherit;
  }
  
  .social-media i {
    font-size: 20px;
    color: #333; /* Cor padrão dos ícones */
  }
  
  .social-media i:hover {
    color: #ad26c9; /* Cor de hover nos ícones */
  }
  .social-media span {
    font-size: 12px;
  }
  .social-media:hover span {
    color: #ad26c9;
  }
  
.fa {
    margin-left: 5px;
}
/* Remove o fundo azul ao clicar */
.navbar-nav .dropdown-menu .dropdown-item:active {
    background-color: inherit;
    color: inherit;
}

.separator {
    margin-right: 10px;
}

@media (max-width: 768px) {
    .nav-top .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1000;
        height: auto;
    }

    .topo .nav-item,
    .topo .social-icons,
    .topo .separator {
        display: none !important; /* Ocultar elementos em telas pequenas */
    }

    .topo .navbar-brand {
        display: flex;
        align-items: center;
    }

    .down .navbar-collapse {
        text-align: left;
    }

    .navbar-toggle {
        display: block;
    }
}
@media (max-width: 576px) {
    .nav-top .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .topo .nav-item,
    .topo .social-icons,
    .topo .separator {
        display: none !important; /* Ocultar elementos em telas pequenas */
    }

    .topo .navbar-brand {
        display: flex;
        align-items: center;
    }

    .down .navbar-collapse {
        text-align: left;
    }

    .navbar-toggle {
        display: block;
    }
}

