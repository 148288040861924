.faq {
  text-align: center;
}

.faq .card-header {
  cursor: pointer;
  background-color: #f8f9fa;
}

.faq .card-header:hover {
  background-color: #e2e6ea;
}

.faq .card-body {
  text-align: left;
}
.text-center-tv {
  text-align: left;
}
.text-accordion {
  text-align: left;
}
@media (max-width: 768) {
  .faq h1{
    font-size: 16px !important;
    text-align: center !important;
  }
}