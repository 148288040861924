.gigaplay-background {
  margin-top: -5px;
  padding-top: 10px;
  background-color: #373737;
  color: #FFF;
}
.gigaplay {
  margin-top: 30px;
  display: flex; 
  flex-direction: column;
  justify-content: start;
}
.gigaplay img {
  width: 250px;
  margin: 10px 0 0 50px;
}

.check-list {
  list-style-type: none;
  padding: 0;
}

.check-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1em;
  font-weight: 300; /* Utilizando peso de fonte fino */
}

.check-icon {
  color: green;
  margin-right: 10px;
}
.gigaplay-nav {
  /* display: flex;
  flex-direction: column; */
  margin-top: -7px;
  text-align: right;
}
.gigaplay-nav img {
  width: 150px;
  margin-bottom: 10px;
}
.gigaplay-nav a {
  text-decoration: none;
  font-size: 16px;
  color: #FFF;
}
.filmes {
  width: 100%;
}
.filmes img{
  width: 100%;
  margin: 5px 0 20px 10px !important;
}
@media (max-width: 768) {

}
