.video-wrapper {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}
.video-dicas {
  border-top: 1px solid #f4f4f4;
}
.video-dicas h1{
  font-size: 28px;
  margin: 30px 0;
  color: #666;
}
