.carousel {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 20px;
}
/* .card-carousel-title {
    
} */
.card-carousel-img img{
    width: 100%;
}
.card-carousel-title {
    width: 500px;
    background: rgba( 255, 255, 255, 0.35 );
    box-shadow: 0 2px 4px 0 #FFF;
    backdrop-filter: blur( 13.5px );
    -webkit-backdrop-filter: blur( 13.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    position: absolute;
    bottom: 150px; /* Distância a partir da parte inferior do Carousel */
    left: -50px; /* Distância a partir da esquerda do Carousel */
    /* width: calc(100% - 40px); Largura total do Carousel menos margens */
    text-align: left; /* Ajuste o alinhamento do texto conforme necessário */
    color: #fff; /* Cor do texto */
    
    padding: 50px; /* Espaçamento interno */
  }
  
  .carousel .card-carousel-title h3 {
    font-size: 40px;
    margin: 0; /* Remova as margens padrão do <h3> */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .carousel .card-carousel-title span {
    font-size: 20px;
    margin: 0; /* Remova as margens padrão do <p> */
  }

  .carousel .card-carousel-title p {
    font-size: 16px;
    margin: 0; /* Remova as margens padrão do <p> */
  }