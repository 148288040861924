.navbar-mobile {
  background-color: #333;
  padding: 10px;
}

.navbar-mobile ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navbar-mobile li {
  margin: 10px 0;
}

.navbar-mobile a {
  color: white;
  text-decoration: none;
  font-size: 1.2em;
}

.navbar-mobile a:hover {
  text-decoration: underline;
}
