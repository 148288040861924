.gigaclient-background {
  margin-bottom: -9px;
  padding-top: 10px;
  background-color: #373737;  
  color: #FFF;
}

.gigaclient {
  width: 100%;
  margin: 5px 50px 0 0;
  display: flex;  
  justify-content: flex-end;
}
.gigaclient img {
  width: 200px;
}
.download-button-app {
  margin-top: 0 !important;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button-app {
  margin: 10px;
}
.text-center-title {
  margin-top: 25px !important;
  width: 100%;
  display: flex;  
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.text-center-title h3 {
  font-size: 20px !important;
  margin-top: 15px !important;
}
.text-center-title h4 {
  font-size: 16px;
  margin-top: 15px !important;
}

.check-list {
  list-style-type: none;
  padding: 0;
}

.check-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1em;
  font-weight: 300; /* Utilizando peso de fonte fino */
}

.check-icon {
  color: green;
  margin-right: 10px;
}

@media (max-width: 768) {

}
