
.rural {
    width: 100%; 
    /* background-color: #f4f4f4;
    box-shadow:  2px 2px 4px rgba(0, 0, 0, 0.3); */
    margin: 0;
}
.row-header {
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.title-rural {
    padding: 40px;
}
.title-rural h1{
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 26px;
}
.title-rural-img img {
    margin: -10px 10px 0 0px !important;
    width: 50px !important;
}
.sub h3 {
    margin-left: 50px !important;
    font-size: 22px;
}
.text { 
  font-size: 12px;   
}
.assinar {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
  /*  */
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .buttons {
    margin: 20px 0 40px 0;
    text-align: center;
  }
  
  .btn-hover {
    width: 200px;
    padding: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
   
    text-align: center;
    border: none;
    background-size: 300% 100%;
  
    border-radius: 20px;
    transition-duration: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
  
  .btn-hover:hover {
    background-position: 100% 0;
    transition-duration: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
  
  .btn-hover:focus {
    outline: none;
  }
  .btn-hover.color-6 {
    background-image: linear-gradient(to right, #009245, #FCEE21, #00A8C5, #D9E021);
    box-shadow: 0 4px 15px 0 rgba(83, 176, 57, 0.75);
  } 

  @media (max-width: 768px) {
    .sub h3{
      font-size: 18px;
      margin-left: 10px !important;
  }
    .title-rural h1 {
      margin-left: 10px;
      margin-bottom: 10px;
      font-size: 18px;
  }
  }