.rural-internet {
  margin: 0;
  padding: 0;
  text-align: center;
}
.title-rural h1 {
  margin: 10px;
  font-size: 32px;
}

.title-rural h6 {
  font-weight: 100;
  font-size: 22px;
}

.rural-card {
  margin-bottom: 20px;
  border: none;
}

.rural-card img {
  max-width: 100%;
  height: auto;
}

.rural-card .card-body {
  background-color: #f8f9fa;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.rural-card .card-body .lead {
  font-size: 1.2em;
  margin-bottom: 10px;
}
.title-rural {
  color: rgb(52, 52, 51) !important;
}
.lead {
  text-align: left;
}
