.telephony-card {
  height: 450px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.telephony-service {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
}
.card-image {
  max-height: 250px;
  object-fit: cover;
}
.telephony-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
}
.title {
  text-align: left !important;
}
