.rural-plan {  
  background-image: url('../../../assets/images/bg-agro.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: -10px;

}
.rural-internet-plan {
  text-align: center;
}
.plan-card {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* background-image: url('../../../assets/images/agroTech.png');
  background-repeat: no-repeat;
  background-size: 100%; */
}
.plan-card .lead {
  font-size: 1.2em;
  margin-bottom: 10px;
}
.plan-card ul {
  list-style-type: none;
  padding: 0;
}
.plan-card ul li {
  margin-bottom: 5px;
}
/* .plan-card:hover {
  background-image: url('../../../assets/images/agroTech.png');
  background-repeat: no-repeat;
  background-size: 100%;
} */
.btn-signup {
  margin-top: 20px;
  font-size: 1.2em;
  padding: 10px 20px;
}

.lead strong {
  color: black !important;
  font-weight: bold;
}
.text-center-rural {
  color: #FFF;
  text-shadow: 1px 1px 2px black;
}
