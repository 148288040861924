/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.trabalhe-conosco-container {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.trabalhe-conosco-btn {
  background-color: #28a745;
  color: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 5px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.trabalhe-conosco-btn:hover {
  background-color: #218838;
}

.trabalhe-conosco-popup {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 250px;
  display: none;
  animation: fadeIn 0.3s ease;
}

.trabalhe-conosco-container:hover .trabalhe-conosco-popup {
  display: block;
}

.trabalhe-conosco-popup img {
  max-width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
}

.trabalhe-conosco-popup h3 {
  margin: 0 0 10px;
  font-size: 18px;
  color: #333;
}

.trabalhe-conosco-popup p {
  margin: 0;
  font-size: 14px;
  color: #555;
}

@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(-50%) translateX(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(-50%) translateX(0);
  }
}


