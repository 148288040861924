.order-card {
  height: 250px !important;
  color: #000;
  padding: 5px !important;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: left !important;
  text-align: left;
}
.observacao span {
  font-size: 20px !important;
}
.text-center {
 color: #333 !important; 
}
.order-card .card-body {
  padding: 10px;
}

.blue-bg {
  background-color: #007bff !important;
}
.blue-bg:hover {
  background-color: #FFFFFF !important;
  box-shadow: 5px 5px 5px #007bff;
  border: 2px solid #007bff;
  width: 100,5%;
}

.green-bg {
  background-color: #28a745 !important;
}
.green-bg:hover{
  background-color: #FFFFFF  !important;
  box-shadow: 5px 5px 5px #28a745;
  border: 2px solid #28a745;
  width: 100,5%;
}

.yellow-bg {
  background-color: #ffc107 !important;
}
.yellow-bg:hover {
  background-color: #FFFFFF !important;
  box-shadow: 5px 5px 5px #ffc107;
  border: 2px solid #ffc107;
  width: 100,5%;
}

.red-bg {
  background-color: #dc3545 !important;
}
.red-bg:hover {
  background-color: #FFFFFF !important;
  box-shadow: 5px 5px 5px #dc3545;
  border: 2px solid #dc3545;
  width: 100,5%;
}

.purple-bg {
  background-color: #6f42c1 !important;
}
.purple-bg:hover {
  background-color: #FFFFFF !important;
  box-shadow: 5px 5px 5px #7a00cc;
  border: 2px solid #7a00cc;
  width: 100,5%;
}

.pink-bg {
  background-color: #e83e8c !important;
}
.pink-bg:hover {
  background-color: #FFFFFF !important;
  box-shadow: 5px 5px 5px #e83e8c;
  border: 2px solid #e83e8c;
  width: 100,5%;
}

.m-b-20 {
  margin-bottom: 20px;
}

.text-right {
  text-align: right;
}

.f-right {
  float: right;
}
.plan-phone {
  color: #000 !important;
}
.icon-card {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.post-it img {
  width: 36px;
}
