/* HalloweenSpider.css */

.spider-container {
  position: fixed;
  top: 0;
  pointer-events: none;
  z-index: 9999;
}

.spider-container.left {
  left: 10%;
}

.spider-container.center {
  left: 50%;
  transform: translateX(-50%);
}

.spider-container.right {
  right: 10%;
}

.spider-web {
  width: 1px;
  height: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  z-index: 0; /* Teia atrás da aranha */
  animation: grow-web 5s ease-in-out forwards;
  opacity: 0.6;
}

.spider-body {
  position: absolute;
  top: 0;
  left: 50%;
  width: 80px; /* Ajuste o tamanho da aranha como preferir */
  height: auto;  /* Mantém a proporção da imagem */
  transform: translate(-50%, 0);
  animation: descend 5s ease-in-out forwards, swing 3s ease-in-out infinite;
  z-index: 1; /* Aranha acima da teia */
}

/* Animação para descer a aranha */
@keyframes descend {
  0% {
    top: -100px;
  }
  100% {
    top: 80vh; /* A aranha desce até 80% da altura da viewport */
  }
}

/* Animação para a teia crescer à medida que a aranha desce */
@keyframes grow-web {
  0% {
    height: 0;
  }
  100% {
    height: 80vh; /* A teia cresce junto com a aranha */
  }
}

/* Animação de balançar */
@keyframes swing {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  50% {
    transform: translateX(-50%) rotate(15deg);
  }
  100% {
    transform: translateX(-50%) rotate(0deg);
  }
}


