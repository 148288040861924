/* src/pages/TrabalheConosco/TrabalheConosco.css */
.trabalhe-container {
  background: linear-gradient(to right, #4a00e0, #8e2de2); /* Exemplo de gradiente roxo */
  color: #fff;
  min-height: 100vh;
  width: 100%;
  padding: 4rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trabalhe-content {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem 3rem;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
}

.trabalhe-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 700;
}

.trabalhe-content p {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.trabalhe-content ul {
  list-style-type: disc;
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;
}

.cta-button-area {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
