/* HalloweenGhosts.css */

.ghost-container {
  position: fixed;
  top: 35%;
  z-index: 9999;
  pointer-events: none;
  opacity: 0.7; /* Fantasmas semitransparentes */
}

.ghost-container.left {
  left: 4%;
}

.ghost-container.center {
  left: 50%;
  transform: translateX(-50%);
}

.ghost-container.right {
  right: 6%;
}

.ghost {
  width: 200px;
  animation: float 8s ease-in-out infinite;
}

/*animação 2*/
.image2 img{
  position: fixed;
  top: 60%;
  z-index: 9999;
  pointer-events: none;
  opacity: 0.7; /* Fantasmas semitransparentes */
}

.image3 img{
  position: fixed;
  top: 15%;
  z-index: 9999;
  pointer-events: none;
  opacity: 0.7; /* Fantasmas semitransparentes */
}

.ghost-container.left2 {
  left: 2%;
}


/* Animação de flutuação para o fantasma */
@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
