.contact-box {
  padding: 40px;
  border-top: 1px solid #f4f4f4;
  background-color: #fff;
}

h1.hire__text {
  font-size: 30px;
  color: #B148C6;
  text-shadow: 1px 1px 2px black;
}
p.hire__text {
  font-size: 18px;
  color: #000;
  line-height: 30px;
  font-weight: 400;
}

input.contact {
  display: block;
  width: 100%;
  padding: 0 20px;
  border: 2px solid rgba(0,0,0, .1);
  border-radius: 5px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  height: 50px;
  line-height: 46px;
  margin: 20px 0px;
  outline: none;
  color: #000;
  font-size: 15px;
  letter-spacing: .1px;
  background: white;
  border: 2px solid hsla(133, 96%, 18%, 0.2);
}

.contact__img {
  border-radius: 10px;
  width: 90%;
  margin-left: 5%;
  overflow: hidden;
  box-shadow: 2px 2px 2px 1px hsla(133, 96%, 18%, 0.2);
}

textarea#message {
  height: 120px;
  background: white;
  padding: 5px 20px;
  width: 100%;
  /* line-height: 46px; */
  color: #555;
  font-size: 11px;
  letter-spacing: .1px;
  border: 2px solid hsla(133, 96%, 18%, 0.2);
}

button.btn.contact.pointer {
  margin: 20px 0px;
  border: 2px solid hsla(133, 96%, 18%, 0.2);
}

button.view__more:hover {
  background-color: #B148C6;
  border: 2px solid #B148C6;
  color: #fff !important;
}
.contact {
  color: #777;
}
.contact-type {
  display: flex;
  justify-content: start; /* Centraliza horizontalmente */
  align-items: center; /* Alinha verticalmente */
  gap: 10px; /* Espaço entre os itens */
}

.contact-type label {
  display: flex;
  align-items: center; /* Alinha o texto verticalmente com o botão de rádio */
  gap: 5px; /* Espaço entre o botão de rádio e o texto */
  font-size: 15px; /* Ajusta o tamanho da fonte */
}
.message {
  margin-top: 17px;
  border-radius: 5px;
}
.form-select {
  border: 2px solid hsla(133, 96%, 18%, 0.2) !important;
  color: #666 !important;
  height: 50px;
  font-size: 11px !important;
}
@media only screen and (max-width: 768px) {
  .contact-box {
    /* margin-top: 90px; */
  }
  h1.hire__text {
      font-size: 26px;
      color: #B148C6;
      text-shadow: unset;
      margin-top: -20px;
  }
  .contact__img {
      border-radius: 10px;
      width: 100%;
      margin-left: 0%;
      overflow: hidden;
      box-shadow: 2px 2px 2px 1px hsla(133, 96%, 18%, 0.2);
  }
}