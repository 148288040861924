.internet-card {
  display: flex;
  justify-content: center;
  align-items: center;
}
.plan-internet {
  font-size: 1.5rem;
  color: #840BAB;
  font-weight: bold;
}
.internet-card img {
  width: 100px;
}
.internet-body {
  font-size: 14px !important;
  font-weight: 500;
  text-align: left;
  margin-bottom: 10px;
}
.internet-body  h2 {
  margin-top: 20px;
  margin-right: 10px !important;
  font-size: 20px;
  color: green;
  font-weight: bold;
}
.internet-body p {
  font-size: 10px !important;
}
@media (max-width: 768px) {
  .app-giga-play {
    margin-top: 20px !important;
  }
  .text-center-title {
    font-size: 20px !important;
    margin-bottom: 10px !important;
  }
}