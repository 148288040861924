* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body {
  width: 100%;
  height: 100%;
  background: #fff1f0;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.title-app {
  margin-left: 30px;
}

.services-box
{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.service
{
  margin: 8px;
}

.flip-box {
  background-color: transparent;
  width: 400px;
  height: 200px;
  border: 1px solid #f1f1f1;  
  border-radius: 10px;
  perspective: 1000px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front, .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flip-box-front {
  background-color: #fff;
  color: black;
  border-radius: 10px;
}

.flip-box-front img
{
  height: 50px;
  width: 50px;
}

.flip-box-back {
  background-color: #f4f4f4;
  color: #fa0101;
  transform: rotateY(180deg);
  border-radius: 10px;
  padding: 16px;
}
.flip-box-back img {
  width: 50px;
}