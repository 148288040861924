.product-card h3{
  margin: 20px 0 20px 0;
  font-size: 14px !important;  
}
.animated-card {
  opacity: 0;
  transform: translateX(40px);
  transition: opacity 0.9s ease-in-out, transform 0.5s ease-in-out;
}

.animated-card.active {
  opacity: 1;
  transform: translateX(0);
}

.animated-card:hover {
  transform: scale(1.03) !important;
  /* Aumenta o tamanho em 10% no hover */
  transition: transform 0.3s ease !important;
  /* Adiciona uma transição suave */
  
}
.title {
  margin-left: 5px;
}
.title h3{
  margin-top: 40px;
}
.card-home {
  margin: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  /* Centraliza verticalmente */
  text-align: center;
  /* Centraliza horizontalmente o texto */
}
.card-home:hover {
  box-shadow:  7px 7px 7px rgba(0, 0, 0, 0.3);
  border: 1px solid #989696;
}
.card-home span {
  margin: 10px 0 20px 0 !important;
}

.modal-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-img img{
  margin: 10px;
  width: 200px;
}
.modal-price span{
  font-size: 20px;
  font-weight: 500;
  color: red;
  text-decoration: line-through;
  
}
.modal-price p{
  font-size: 26px;
  font-weight: bold;
  color: green;
}
.contato-modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.contato-modal a{
  color: #000;
  text-decoration: none;
}
.contato-modal img{
  width: 50px;
}