.phone {
  background-color: #f4f4f4;
  padding-bottom: 0;
}

.phone-container {
  width: 100%;
  padding: 40px;
}

.title-phone {
  display: flex;
  flex-direction: row;
}

.title-phone img {
  width: 60px;
  height: 70px;
}

.title-phone h1 {
  font-size: 28px;
}

.title-phone h3 {
  font-size: 18px;
}

.text {
  font-size: 16px;
  text-align: start;
}
.separator-bar {
  margin: 0 !important;
}
/*  */
.container-phone {
  display: flex;
  align-items: last baseline;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.buttons-phone {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 320px; /* Altura do contêiner */
  margin-left: 40px;
  width: 100%;
}

.btn-hover {
  width: 200px;
  padding: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;

  text-align: center;
  border: none;
  background-size: 300% 100%;

  border-radius: 20px;
  transition-duration: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  transition-duration: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-9 {
  background-image: linear-gradient(to right, #f605e6, #9e06f1, #9e06f1, #f605e6);
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}

@media (max-width: 768px) {
  .phone-container {
      padding: 20px; /* Reduz o preenchimento */
      height: auto; /* Ajusta a altura para automático */
  }

  .title-phone img {
      width: 30px; /* Reduz o tamanho da imagem */
      height: 35px; /* Reduz a altura da imagem */
  }

  .title-phone h1 {
      font-size: 22px; /* Diminui o tamanho da fonte do título */
  }

  .title-phone h3,
  .text {
      font-size: 14px; /* Diminui o tamanho da fonte dos subtítulos e texto */
  }

  .buttons-phone {
      margin-right: 20px; /* Reduz a margem direita */
      align-items: center; /* Alinha os botões ao centro */
      height: auto; /* Ajusta a altura para automático */
  }

  .btn-hover {
      width: 100%; /* Faz o botão ocupar toda a largura */
      padding: 15px; /* Reduz o preenchimento do botão */
  }
}

@media (max-width: 480px) {
  .phone-container {
      padding: 10px; /* Reduz ainda mais o preenchimento */
  }

  .title-phone h1 {
      font-size: 18px; /* Reduz ainda mais o tamanho da fonte do título */
  }

  .title-phone h3,
  .text {
      font-size: 12px; /* Reduz ainda mais o tamanho da fonte dos subtítulos e texto */
  }

  .buttons-phone {
      margin-right: 10px; /* Reduz ainda mais a margem direita */
  }
}

