.tv-plans {
  text-align: center;
}

.plan-card {
  margin-bottom: 20px;
}

.plan-image {
  max-height: 150px;
  object-fit: cover;
}

.plan-card .card-title {
  font-size: 1.2em;
  font-weight: bold;
}
.img-tv img {
  width: 40px;
}
.icon-tv {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.icon-tv img {
  width: 40px;
}
.plan-tv-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.plan-tv-footer p {
  font-size: 12px;
}
.custom-modal-dark-body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-modal-dark-body img {
  width: 45%;
}
.custom-modal-dark-body-text {
  display: flex;
  flex-direction: column;
}
.custom-modal-dark-body-text h1{
  font-size: 28px;
}
.custom-modal-dark-body-text h2{
  font-size: 18px;
}
.custom-modal-dark-body-text h3{
  color: rgb(3, 245, 3);
}
.custom-modal-dark-body-text span{
  font-size: 18px;
}
.custom-modal-dark .modal-content {
  background-color: #373737;
  color: white;
}
.custom-modal-dark .modal-header, 
.custom-modal-dark .modal-body, 
.custom-modal-dark .modal-footer {
  border: none;
  position: relative;
}

.custom-modal-dark .modal-header::after,
.custom-modal-dark .modal-body::after,
.custom-modal-dark .modal-footer::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right, #6253e1, #852D91, #A3A1FF, #F24645); /* Exemplo de gradiente */
}

.custom-modal-dark .modal-header::after {
  top: 100%;
}

.custom-modal-dark .modal-body::after {
  top: 100%;
}

.custom-modal-dark .modal-footer::after {
  bottom: 100%;
}

