.carousel {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 20px;
}
.card-carousel-title {
    
}
.card-carousel-img img{
    width: 100%;
    height: 500px;
}
.card-carousel-title {
    width: 500px;
    background: rgba( 255, 255, 255, 0.35 );
    box-shadow: 0 2px 4px 0 #FFF;
    backdrop-filter: blur( 13.5px );
    -webkit-backdrop-filter: blur( 13.5px );
    border-radius: 10px;
    border: 2px solid rgb(255, 255, 255);
    position: absolute;
    bottom: 50px; /* Distância a partir da parte inferior do Carousel */
    left: -70px; /* Distância a partir da esquerda do Carousel */
    /* width: calc(100% - 40px); Largura total do Carousel menos margens */
    text-align: left; /* Ajuste o alinhamento do texto conforme necessário */
    color: #fff; /* Cor do texto */
    
    padding: 50px; /* Espaçamento interno */
  }
  
  .carousel .card-carousel-title h3 {
    color: #660099;
    font-size: 40px;
    margin: 0; /* Remova as margens padrão do <h3> */
    text-shadow: 1px 1px 1px #fff;
  }
  
  .carousel .card-carousel-title span {
    color: #000;
    font-size: 26px;
    font-weight: 400;
    margin: 0;
    text-align: center !important;
  }

  .carousel .card-carousel-title p {
    color: #2e2d2e;
    font-size: 24px;
    margin: 0; /* Remova as margens padrão do <p> */
    font-weight: 700;
  }
  .card-carousel-title  {  
    text-align: center;
  }
  .card-carousel-title img {
    width: 200px;
    margin-bottom: 10px;  
  }
  .card-carousel-title-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
.card-carousel-title-body img {
  width: 50px;
}
