/* Avatar fixo no rodapé */
.chatbot-avatar {
  position: fixed;
  bottom: 40px;
  right: 25px;
  cursor: pointer;
  z-index: 1000;
}

.chatbot-avatar img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid #7a00cc;
  background-color: #7a00cc;
  padding: 2px;
}

/* Chatbox flutuante */
.chatbot-container {
  position: absolute;
  bottom: 65px;
  right: 20px;
  width: 400px;
  max-width: 100%;
  z-index: 1001;
}

/* Header do chat */
.chatbot-header {
  background-color: #7a00cc !important;
  color: #fff !important;
  border-radius: 15px 15px 0 0;
  text-align: center;
}

/* Corpo do chat */
.chatbot-body {
  max-height: 400px;
  overflow-y: auto;
  padding: 5px;
  font-size: 14px;
}

/* Mensagens do bot e do usuário */
.chatbot-message {
  margin-bottom: 10px;
}

.chatbot-bot-message {
  text-align: left;
}

.chatbot-user-message {
  text-align: left;
}

.chatbot-message-bubble {
  border-radius: 15px;
  padding: 0px;
  color: #fff;
}

.chatbot-bot-bubble {
  background-color: #7a00cc;
}

.chatbot-user-bubble {
  background-color: #ff007f;
}

/* Botões de opção */
.chatbot-options {
  display: grid;
  gap: 10px;
}

.typing-dots {
  display: inline-block;
  font-size: 24px;
  color: #ff007f;
  letter-spacing: 2px;
  animation: blink 1.4s infinite both;
}

.typing-dots span {
  animation: blink 1.4s infinite both;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}


/* Media queries para dispositivos móveis */
@media (max-width: 768px) {
  .chatbot-container {
    width: 100%;
    bottom: 0;
    right: 0;
    max-width: 100%;
  }
  .chatbot-avatar {
    position: fixed;
    bottom: 8px;
    right: 10px;
    cursor: pointer;
    z-index: 1000;
  }
}
