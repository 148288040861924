.faq {
  text-align: center;
}

.faq .card-header {
  cursor: pointer;
  background-color: #f8f9fa;
}

.faq .card-header:hover {
  background-color: #e2e6ea; /* Cor de fundo quando o mouse passa sobre o header */
  color: inherit; /* Mantém a cor original do texto */
}

.faq .card-body {
  text-align: left;
}

/* Sobrescrevendo estilo padrão do Bootstrap para Accordion.Header */
.accordion-button {
  color: inherit; /* Mantém a cor original do texto */
  background-color: #f8f9fa; /* Fundo claro padrão */
}

.accordion-button:hover {
  color: inherit; /* Mantém a cor original do texto */
  background-color: #e2e6ea; /* Altere para a cor desejada */
}

.accordion-button:not(.collapsed) {
  color: inherit;
  background-color: #e2e6ea; /* Fundo claro quando o accordion está aberto */
}

.accordion-button:focus {
  box-shadow: none; /* Remove o box-shadow padrão ao focar */
}

.obs-phone {
  font-size: 11px;
  text-align: left;
  margin-top: 10px;
}

.text-accordion {
  text-align: left;
}
